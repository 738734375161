import React, { useEffect, useState } from "react";
import { Avatar, Card, Search, Typography } from "../../../../components";
import moment from "moment";
import "./index.scss";

const ChatHead = ({ chatUsers, customer, handleChat, handleSearch }) => {
  const noImage = "https://api.tabsurfaces.com/images/avatar.png";
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(chatUsers);
  }, [chatUsers]);

  const handleSelection=(e)=>{
    handleChat(e)
   const updatedList= list.map(user => {
      if (user.email === e.email) {
        return { ...user, isSeen: true }; 
      }
      return user; 
    });
    setList(updatedList)
  }

  return (
    <>
      <Card variant={"chat-card"} className={"h-100"}>
        <div className="flex-fill-row">
          <div className="mb-4">
            <Search
              placeholder={"Search people or message"}
              handleSearchBar={handleSearch}
            />
          </div>
          <div className="flex-fill-row overflow-hidden overflow-y-auto chat-trigger-container">
            {chatUsers?.length ? (
              <Card variant={"chat-card"} className="border-0">
                <div className="chat-head overflow-auto">
                  {list.map((user) => {
                    return (
                      <Card
                        variant={"chat-card"}
                        className={`${
                          user?.email === customer?.email
                            ? "chat-each  active-chat online"
                            : "chat-each  online"
                        } ${!user?.isSeen && " opened-chat"}`}
                      >
                        <div
                          className="d-flex justify-content-between flex-wrap"
                          onClick={() => handleSelection(user)}
                        >
                          <div className="d-flex align-items-center chat-list-box">
                            <Avatar
                              customClass={"me-2"}
                              variant={"header-pro-thumb"}
                              bsPrefix
                              src={user.image ?? noImage}
                            />
                            <div className="d-flex flex-column w-100">
                              <div className="d-flex flex-wrap people-name">
                                <Typography
                                  label={user.name}
                                  variant={"dynamic-text"}
                                  fontSize={"text-15 text-truncate"}
                                  fontWeight={"font-semibold"}
                                  color={"color-dark01"}
                                  margin="mb-0 me-2 w-100"
                                />
                              </div>
                              {user?.isSeen ? <Typography
                                label={user.lastMessage}
                                variant={"dynamic-text"}
                                fontSize={"text-11 text-truncate"}
                                fontWeight={"font-regular"}
                                color={"color-gray03"}
                                margin="mb-0"
                              /> : <Typography
                              label={user.lastMessage}
                              variant={"dynamic-text"}
                              fontSize={"text-14 text-truncate"}
                              fontWeight={"font-bold"}
                              margin="mb-0"
                            />}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <Typography
                              label={
                                user?.lastMessageTime
                                  ? moment(user?.lastMessageTime?.toDate()).format(
                                      "LT"
                                    )
                                  : ""
                              }
                              variant={"dynamic-text"}
                              fontSize={"text-11 text-truncate"}
                              fontWeight={"font-regular"}
                              color={"color-gray03"}
                              margin="mb-0"
                            />
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </Card>
            ) : (
              <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center p-4 p-lg-5 mb-5">
                <div className="p-3 w-75">
                  <img
                    src="/images/No-results.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <Typography
                  variant={"heading3"}
                  label={"No Results"}
                ></Typography>
                <Typography
                  variant={"dynamic-text"}
                  fontSize={"text-15"}
                  fontWeight={"font-medium"}
                  color={"color-gray"}
                  margin="mb-0 text-center"
                  label={"Sorry, there are no results for this search."}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default React.memo(ChatHead);
