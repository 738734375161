import axios from "axios";
import {
    ASSIGN_SALES_USER_TO_CUSTOMERS_BEGINS,
    ASSIGN_SALES_USER_TO_CUSTOMERS_FAILURE,
    ASSIGN_SALES_USER_TO_CUSTOMERS_SUCCESS,
    CHANGE_CUSTOMER_PRODUCT_NAME_BEGINS,
    CHANGE_CUSTOMER_PRODUCT_NAME_FAILURE,
    CHANGE_CUSTOMER_PRODUCT_NAME_SUCCESS,
    CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS,
    CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE,
    CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS,
    CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_BEGINS,
    CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_FAILURE,
    CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_SUCCESS,
    CREATE_SALES_USER_BEGINS, CREATE_SALES_USER_FAILURE, CREATE_SALES_USER_SUCCESS, CREATE_SUBADMIN_BEGINS,
    CREATE_SUBADMIN_FAILURE, CREATE_SUBADMIN_SUCCESS, DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS, DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE, DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS, DELETE_PRODUCT_IMAGE_BEGINS, DELETE_PRODUCT_IMAGE_FAILURE, DELETE_PRODUCT_IMAGE_SUCCESS, GET_ASSIGN_PRODUCTS_LIST_BEGINS, GET_ASSIGN_PRODUCTS_LIST_FAILURE, GET_ASSIGN_PRODUCTS_LIST_SUCCESS, GET_CUSTOMER_CART_LIST_BEGINS, GET_CUSTOMER_CART_LIST_FAILURE, GET_CUSTOMER_CART_LIST_SUCCESS, GET_CUSTOMER_DETAILS_BY_ID_BEGINS, GET_CUSTOMER_DETAILS_BY_ID_FAILURE, GET_CUSTOMER_DETAILS_BY_ID_SUCCESS, GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS, GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE, GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS, GET_CUSTOMER_LIST_BEGINS, GET_CUSTOMER_LIST_FAILURE,
    GET_CUSTOMER_LIST_SUCCESS, GET_CUSTOMER_OFFERS_LIST_BEGINS, GET_CUSTOMER_OFFERS_LIST_FAILURE, GET_CUSTOMER_OFFERS_LIST_SUCCESS, GET_CUSTOMER_ORDER_LIST_BEGINS, GET_CUSTOMER_ORDER_LIST_FAILURE, GET_CUSTOMER_ORDER_LIST_SUCCESS, GET_CUSTOMER_WISHLIST_BEGINS, GET_CUSTOMER_WISHLIST_FAILURE, GET_CUSTOMER_WISHLIST_SUCCESS, GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS, GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE, GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS, GET_SALES_USERS_BEGINS, GET_SALES_USERS_FAILURE, GET_SALES_USERS_SUCCESS,
    GET_SALES_USER_DETAILS_BY_ID_BEGINS,
    GET_SALES_USER_DETAILS_BY_ID_FAILURE,
    GET_SALES_USER_DETAILS_BY_ID_SUCCESS,
    GET_SUBADMINS_BEGINS, GET_SUBADMINS_FAILURE, GET_SUBADMINS_SUCCESS, GET_SUBADMIN_PERMISSIONS_BEGINS,
    GET_SUBADMIN_PERMISSIONS_FAILURE, GET_SUBADMIN_PERMISSIONS_SUCCESS, GET_SUB_ADMIN_DETAILS_BEGINS, GET_SUB_ADMIN_DETAILS_FAILURE, GET_SUB_ADMIN_DETAILS_SUCCESS, GET_SUB_USERS_LIST_BEGINS, GET_SUB_USERS_LIST_FAILURE, GET_SUB_USERS_LIST_SUCCESS, INVITE_CUSTOMERS_BEGINS,
    INVITE_CUSTOMERS_FAILURE, INVITE_CUSTOMERS_SUCCESS, INVITE_INDIVIDUAL_CUSTOMERS_BEGINS, INVITE_INDIVIDUAL_CUSTOMERS_FAILURE, INVITE_INDIVIDUAL_CUSTOMERS_SUCCESS, SEARCH_CUSTOMER_EMAIL_BY_NAME_BEGINS, SEARCH_CUSTOMER_EMAIL_BY_NAME_FAILURE, SEARCH_CUSTOMER_EMAIL_BY_NAME_SUCCESS, SEARCH_USER_BY_NAME_BEGINS, SEARCH_USER_BY_NAME_FAILURE, SEARCH_USER_BY_NAME_SUCCESS, UPDATE_CUSTOMERS_BEGINS, UPDATE_CUSTOMERS_FAILURE,
    UPDATE_CUSTOMERS_SUCCESS, UPDATE_EMAIL_AND_INVITE_CUSTOMER_BEGINS, UPDATE_EMAIL_AND_INVITE_CUSTOMER_FAILURE, UPDATE_EMAIL_AND_INVITE_CUSTOMER_SUCCESS, UPDATE_MEMBERSHIP_BEGINS, UPDATE_MEMBERSHIP_FAILURE, UPDATE_MEMBERSHIP_SUCCESS, UPDATE_SALES_USER_BEGINS, UPDATE_SALES_USER_SUCCESS, UPDATE_SUBADMIN_BEGINS,
    UPDATE_SUBADMIN_FAILURE, UPDATE_SUBADMIN_SUCCESS, UPDATE_USER_STATUS_BEGINS, UPDATE_USER_STATUS_FAILURE, UPDATE_USER_STATUS_SUCCESS, UPLOAD_PROFILE_IMAGE_BEGINS, UPLOAD_PROFILE_IMAGE_FAILURE, UPLOAD_PROFILE_IMAGE_SUCCESS,
    GET_CUSTOMER_SALES_USERS_BEGINS, GET_CUSTOMER_SALES_USERS_SUCCESS, GET_CUSTOMER_SALES_USERS_FAILURE, DELETE_SALES_USER_CUSTOMER_BEGINS, DELETE_SALES_USER_CUSTOMER_SUCCESS, DELETE_SALES_USER_CUSTOMER_FAILURE, POST_SUB_USERS_LIST_BEGINS, POST_SUB_USERS_LIST_SUCCESS,POST_SUB_USERS_LIST_FAILURE ,

} from "../../../constants";
import api from "../../../utils/axios";


const getCustomerListBegins = () => ({ type: GET_CUSTOMER_LIST_BEGINS });
const getCustomerListSuccess = (data) => ({ type: GET_CUSTOMER_LIST_SUCCESS, data });
const getCustomerListFailure = (data) => ({ type: GET_CUSTOMER_LIST_FAILURE, data });

const inviteCustomersBegins = () => ({ type: INVITE_CUSTOMERS_BEGINS });
const inviteCustomersSuccess = (data) => ({ type: INVITE_CUSTOMERS_SUCCESS, data });
const inviteCustomersFailure = () => ({ type: INVITE_CUSTOMERS_FAILURE });

const updateCustomersBegins = () => ({ type: UPDATE_CUSTOMERS_BEGINS })
const updateCustomerSuccess = (data) => ({ type: UPDATE_CUSTOMERS_SUCCESS, data })
const updateCustomerFailure = () => ({ type: UPDATE_CUSTOMERS_FAILURE })

const getSubAdminsBegins = () => ({ type: GET_SUBADMINS_BEGINS })
const getSubAdminsSuccess = (data) => ({ type: GET_SUBADMINS_SUCCESS, data })
const getSubAdminsFailure = () => ({ type: GET_SUBADMINS_FAILURE })

const createSubAmdinBegins = () => ({ type: CREATE_SUBADMIN_BEGINS })
const createSubAdminSuccess = (data) => ({ type: CREATE_SUBADMIN_SUCCESS, data })
const createSubAminFailure = () => ({ type: CREATE_SUBADMIN_FAILURE })

const updateSubAdminBegins = () => ({ type: UPDATE_SUBADMIN_BEGINS })
const updateSubAdminSuccess = (data) => ({ type: UPDATE_SUBADMIN_SUCCESS, data })
const updateSubAdminFailure = () => ({ type: UPDATE_SUBADMIN_FAILURE })

const createSalesUserBegins = () => ({ type: CREATE_SALES_USER_BEGINS })
const createSalesUserSuccess = () => ({ type: CREATE_SALES_USER_SUCCESS })
const createSalesUserFailure = () => ({ type: CREATE_SALES_USER_FAILURE })

const getSalesUserBegins = () => ({ type: GET_SALES_USERS_BEGINS })
const getSalesUserSuccess = (data) => ({ type: GET_SALES_USERS_SUCCESS, data })
const getSalesUserFailure = () => ({ type: GET_SALES_USERS_FAILURE })

const updateSalesUserBegins = () => ({ type: UPDATE_SALES_USER_BEGINS })
const updateSalesUserSuccess = (data) => ({ type: UPDATE_SALES_USER_SUCCESS, data })
const updateSalesUserFailure = () => ({ type: UPDATE_SUBADMIN_FAILURE })

const searchCustomerByNameBegins = () => ({ type: SEARCH_USER_BY_NAME_BEGINS })
const searchCustomerByNameSuccess = (data) => ({ type: SEARCH_USER_BY_NAME_SUCCESS, data })
const searchCustomerByNameFailure = () => ({ type: SEARCH_USER_BY_NAME_FAILURE })

const getSubadminPermisionsBegins = () => ({ type: GET_SUBADMIN_PERMISSIONS_BEGINS })
const getSubadminPermisionsSuccess = (data) => ({ type: GET_SUBADMIN_PERMISSIONS_SUCCESS, data })
const getSubAdminPermissionFailure = () => ({ type: GET_SUBADMIN_PERMISSIONS_FAILURE })

const updateUserStatusBegins = () => ({ type: UPDATE_USER_STATUS_BEGINS })
const updateUserStatusSuccess = (data) => ({ type: UPDATE_USER_STATUS_SUCCESS, data })
const updateUserStatusFailure = () => ({ type: UPDATE_USER_STATUS_FAILURE })

const getCustomerDetailsByIdBegins = () => ({ type: GET_CUSTOMER_DETAILS_BY_ID_BEGINS })
const getCustomerDetailsByIdSuccess = (data) => ({ type: GET_CUSTOMER_DETAILS_BY_ID_SUCCESS, data })
const getCustomerDetailsByIdFailure = () => ({ type: GET_CUSTOMER_DETAILS_BY_ID_FAILURE })

const assignSalesUsertoCustomersBegins = () => ({ type: ASSIGN_SALES_USER_TO_CUSTOMERS_BEGINS })
const assignSalesUsertoCustomersSuccess = () => ({ type: ASSIGN_SALES_USER_TO_CUSTOMERS_SUCCESS })
const assignSalesUsertoCustomersFailure = () => ({ type: ASSIGN_SALES_USER_TO_CUSTOMERS_FAILURE })

const getSubUsersListBegins = () => ({ type: GET_SUB_USERS_LIST_BEGINS })
const getSubUsersListSuccess = (data) => ({ type: GET_SUB_USERS_LIST_SUCCESS, data })
const getSubUsersListFails = () => ({ type: GET_SUB_USERS_LIST_FAILURE })

const postSubUsersListBegins = () => ({ type:  POST_SUB_USERS_LIST_BEGINS })
const postSubUsersListSuccess = (data) => ({ type: POST_SUB_USERS_LIST_SUCCESS, data })
const postSubUsersListFails = () => ({ type: POST_SUB_USERS_LIST_FAILURE })

const getCustomerOfferListBegins = () => ({ type: GET_CUSTOMER_OFFERS_LIST_BEGINS })
const getCustomerOfferListSuccess = (data) => ({ type: GET_CUSTOMER_OFFERS_LIST_SUCCESS, data })
const getCustomerOfferListFails = () => ({ type: GET_CUSTOMER_OFFERS_LIST_FAILURE })

const getCustomerCartListBegins = () => ({ type: GET_CUSTOMER_CART_LIST_BEGINS })
const getCustomerCartListSuccess = (data) => ({ type: GET_CUSTOMER_CART_LIST_SUCCESS, data })
const getCustomerCartListFailure = () => ({ type: GET_CUSTOMER_CART_LIST_FAILURE })

const getOrderRatingsAndFeedbackBegins = () => ({ type: GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS })
const getOrderRatingsAndFeedbackSuccess = (data) => ({ type: GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS, data })
const getOrderRatingsAndFeedbackFails = () => ({ type: GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE })

const getSubAdminDetailsByIdBegins = () => ({ type: GET_SUB_ADMIN_DETAILS_BEGINS })
const getSubAdminDetailsByIdSuccess = (data) => ({ type: GET_SUB_ADMIN_DETAILS_SUCCESS, data })
const getSubAdminDetailsByIdFailure = () => ({ type: GET_SUB_ADMIN_DETAILS_FAILURE })

const getSalesUserDetailsByIdBegins = () => ({ type: GET_SALES_USER_DETAILS_BY_ID_BEGINS })
const getSalesUserDetailsByIdSuccess = (data) => ({ type: GET_SALES_USER_DETAILS_BY_ID_SUCCESS, data })
const getSalesUserDetailsByIdFails = () => ({ type: GET_SALES_USER_DETAILS_BY_ID_FAILURE })

const getCustomerWishlistBegins = () => ({ type: GET_CUSTOMER_WISHLIST_BEGINS })
const getCustomerWishlistSuccess = (data) => ({ type: GET_CUSTOMER_WISHLIST_SUCCESS, data })
const getCustomerWishlistFails = () => ({ type: GET_CUSTOMER_WISHLIST_FAILURE })

const getCustomerOrdersListBegins = () => ({ type: GET_CUSTOMER_ORDER_LIST_BEGINS })
const getCustomerOrdersListSuccess = (data) => ({ type: GET_CUSTOMER_ORDER_LIST_SUCCESS, data })
const getCustomerOrdersListFails = () => ({ type: GET_CUSTOMER_ORDER_LIST_FAILURE })

const getInterestedProductsListBegins = () => ({ type: GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS })
const getInterestedProductsListSuccess = (data) => ({ type: GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS, data })
const getInterestedProductsListFails = () => ({ type: GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE })

const inviteIndividualCustomersBegins = () => ({ type: INVITE_INDIVIDUAL_CUSTOMERS_BEGINS })
const inviteIndividualCustomersSuccess = (data) => ({ type: INVITE_INDIVIDUAL_CUSTOMERS_SUCCESS, data })
const inviteIndividualCustomersFailure = () => ({ type: INVITE_INDIVIDUAL_CUSTOMERS_FAILURE })

const searchCustomerEmailByNameBegins = () => ({ type: SEARCH_CUSTOMER_EMAIL_BY_NAME_BEGINS })
const searchCustomerEmailByNameSuccess = (data) => ({ type: SEARCH_CUSTOMER_EMAIL_BY_NAME_SUCCESS, data })
const searchCustomerEmailByNameFailure = () => ({ type: SEARCH_CUSTOMER_EMAIL_BY_NAME_FAILURE })

const uploadProfileImageBegins = () => ({ type: UPLOAD_PROFILE_IMAGE_BEGINS })
const uploadProfileImageSuccess = (data) => ({ type: UPLOAD_PROFILE_IMAGE_SUCCESS, data })
const uploadProfileImageFailure = () => ({ type: UPLOAD_PROFILE_IMAGE_FAILURE })

const updateMembershipBegins = () => ({ type: UPDATE_MEMBERSHIP_BEGINS })
const updateMembershipSuccess = (data) => ({ type: UPDATE_MEMBERSHIP_SUCCESS, data })
const updateMembershipFailure = () => ({ type: UPDATE_MEMBERSHIP_FAILURE })

const updateEmailAndInviteCustomerBegins = () => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_BEGINS })
const updateEmailAndInviteCustomerSuccess = (data) => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_SUCCESS, data })
const updateEmailAndInviteCustomerFailure = () => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_FAILURE })

const customerInviteAgainBegins = () => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_BEGINS })
const customerInviteAgainSuccess = (data) => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_SUCCESS, data })
const customerInviteAgainFailure = () => ({ type: UPDATE_EMAIL_AND_INVITE_CUSTOMER_FAILURE })

const getAssignProductsListBegins = () => ({ type: GET_ASSIGN_PRODUCTS_LIST_BEGINS })
const getAssignProductsListSuccess = (data) => ({ type: GET_ASSIGN_PRODUCTS_LIST_SUCCESS, data })
const getAssignProductsListFailure = () => ({ type: GET_ASSIGN_PRODUCTS_LIST_FAILURE })

const changeCustomerProductNameBegins = () => ({ type: CHANGE_CUSTOMER_PRODUCT_NAME_BEGINS })
const changeCustomerProductNameSuccess = (data) => ({ type: CHANGE_CUSTOMER_PRODUCT_NAME_SUCCESS, data })
const changeCustomerProductNameFailure = () => ({ type: CHANGE_CUSTOMER_PRODUCT_NAME_FAILURE })

const newProductNotificationSettingsBegins = () => ({ type: CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_BEGINS })
const newProductNotificationSettingsSuccess = (data) => ({ type: CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_SUCCESS, data })
const newProductNotificationSettingsFailure = () => ({ type: CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_FAILURE })

const additionalNotificationSettingsBegins = () => ({ type: CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS })
const additionalNotificationSettingsSuccess = (data) => ({ type: CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS, data })
const additionalNotificationSettingsFailure = () => ({ type: CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE })

const deleteAdditionalNotificationSettingsBegins = () => ({ type: DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS })
const deleteAdditionalNotificationSettingsSuccess = () => ({ type: DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS })
const deleteAdditionalNotificationSettingsFailure = () => ({ type: DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE })

const getCustomerSalesUserBegins = () => ({ type: GET_CUSTOMER_SALES_USERS_BEGINS })
const getCustomerSalesUserSuccess = (data) => ({ type: GET_CUSTOMER_SALES_USERS_SUCCESS, data })
const getCustomerSalesUserFailure = () => ({ type: GET_CUSTOMER_SALES_USERS_FAILURE })

const deleteProfileImageBegins = () => ({ type: DELETE_PRODUCT_IMAGE_BEGINS })
const deleteProfileImageSuccess = (data) => ({ type: DELETE_PRODUCT_IMAGE_SUCCESS, data })
const deleteProfileImageFailure = () => ({ type: DELETE_PRODUCT_IMAGE_FAILURE })

const deleteSalesUserCustomerBegins = () => ({ type: DELETE_SALES_USER_CUSTOMER_BEGINS })
const deleteSalesUserCustomerSuccess = (data) => ({ type: DELETE_SALES_USER_CUSTOMER_SUCCESS, data })
const deleteSalesUserCustomerFailure = () => ({ type: DELETE_SALES_USER_CUSTOMER_FAILURE })

// CUSTOMER GET LIST
export const actionGetCustomers = (limit, page, searchText, membership, usageStatus, salesUser, sortBy, sortOrder) => async (dispatch) => {

    dispatch(getCustomerListBegins());
    try {
        const res = await api.get(`/admin/list/customer`, { params: { limit, offset: page, search: searchText, membership, usage_status: usageStatus, sales_user: salesUser, sortBy, sortOrder } });
        if (res.status === 200) {
            let { response } = res.data;
            response = { ...response, data: response?.data?.map(res => ({ ...res, checked: false })) };
            dispatch(getCustomerListSuccess(response))
            return { success: true, message: 'success' }

        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getCustomerListFailure())
        return { success: false, message: message }
    }
}


//INVITE CUSTOMER API
export const actionInviteCustomers = (data) => async (dispatch) => {
    dispatch(inviteCustomersBegins());
    try {
        const res = await api.post('/admin/invite/customer', data)
        if (res.status === 200) {
            dispatch(inviteCustomersSuccess())
            return { success: true, message: 'Customers Invited successfully' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(inviteCustomersFailure())
        return { success: false, message: message }
    }
}

//INVITEINDIVIDUAL CUSTOMER API
export const actionInviteIndividualCustomers = (data) => async (dispatch) => {
    dispatch(inviteIndividualCustomersBegins());
    try {
        const res = await api.post('/admin/invite/customer', data)
        if (res.status === 200) {
            dispatch(inviteIndividualCustomersSuccess())
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(inviteIndividualCustomersFailure())
        return { success: false, message: message }
    }
}

// CUSTOMER UPDATE API
export const actionUpdateCustomers = (data) => async (dispatch) => {
    dispatch(updateCustomersBegins());
    try {
        const res = await api.put('/admin/update/customer', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Customer updated successfully' }
            dispatch(updateCustomerSuccess(data))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateCustomerFailure())
        return { success: false, message: message }
    }
}

// GET SALES USERS API
export const actionGetSalesUSers = (search, limit, page, sort, status) => async (dispatch) => {
    dispatch(getSalesUserBegins());
    try {

        const res = await api.get(`/admin/list/sales`, { params: { search, limit, offset: page, sortBy: sort?.sortBy, sortOrder: sort?.sortOrder, status } })
        if (res.status === 200) {
            const { response } = res.data || { response: { data: [], total: 0 } };
            dispatch(getSalesUserSuccess(response))
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went0 wrong' }
        dispatch(getSalesUserFailure())
        return { success: false, message: message }
    }
}

// UPDATE SALES USERS API
export const actionUpdateSalesUsers = (data) => async (dispatch) => {
    dispatch(updateSalesUserBegins());
    try {
        const res = await api.put('/admin/update-salesuser', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Sales user updated successfully' }
            dispatch(updateSalesUserSuccess(data))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateSalesUserFailure())
        return { success: false, message: message }
    }
}

// CREATE SALES USERS API
export const actionCreateSalesUsers = (data) => async (dispatch) => {
    dispatch(createSalesUserBegins());
    try {
        const res = await api.post('/admin/create-salesuser', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Sales user Created Successfully' }
            dispatch(createSalesUserSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createSalesUserFailure())
        return { success: false, message: message }
    }
}

// SUBADMIN LIST API
export const getSubadmins = (search, limit, page, sort, status) => async (dispatch) => {

    dispatch(getSubAdminsBegins());
    try {
        const res = await api.get(`/admin/get/subadmin?`, { params: { search: search, limit: limit, offset: page, sortBy: sort?.sortBy, sortOrder: sort?.sortOrder, status: status } })
        if (res.status === 200) {
            const { response } = res.data || { response: { data: [], total: 0 } };
            dispatch(getSubAdminsSuccess(response))
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getSubAdminsFailure())
        return { success: false, message: message }
    }
}

// UPDATE SUB ADMIN API
export const updateSubAdmin = (data) => async (dispatch) => {
    dispatch(updateSubAdminBegins());
    try {
        const res = await api.put('/admin/update/subadmin', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Sub-admin Updated Successfully' }
            dispatch(updateSubAdminSuccess(data))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateSubAdminFailure())
        return { success: false, message: message }
    }
}


//  CREATE SUB ADMIN API
export const createSubAdmin = (data) => async (dispatch) => {
    dispatch(createSubAmdinBegins());
    try {
        const res = await api.post('/admin/create/subadmin', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Subadmin Updated Successfully' }
            dispatch(createSubAdminSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createSubAminFailure())
        return { success: false, message: message }
    }
}


// SEARCH USER BY NAME API
export const actionSearchUserByName = (data) => async (dispatch) => {
    dispatch(searchCustomerByNameBegins());
    try {
        const res = await api.get(`/admin/list/search-user-by-name`, { params: data })
        if (res.status === 200) {
            const { response } = res.data || { response: { data: [], total: 0 } };

            dispatch(searchCustomerByNameSuccess({ role_id: data?.role_id, response: response }))
            return { success: true, message: 'success', response: response }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(searchCustomerByNameFailure())
        return { success: false, message: message, response: [] }
    }
}

// SEARCH CUSTOMER EMAIL BY NAME API
export const actionSearchCustomerEmailByName = (data) => async (dispatch) => {
    dispatch(searchCustomerEmailByNameBegins());
    try {
        const res = await api.get(`/admin/list/search-customer-by-name`, { params: data })
        if (res.status === 200) {
            const response = res?.data?.data || { response: { data: [], total: 0 } };
            dispatch(searchCustomerEmailByNameSuccess({ role_id: data?.role_id, response: response }))
            return { success: true, message: 'success', response: response }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(searchCustomerEmailByNameFailure())
        return { success: false, message: message, response: [] }
    }
}

export const actionGetPermissions = () => async (dispatch) => {
    dispatch(getSubadminPermisionsBegins());
    try {
        const res = await api.get(`/admin/list/permission`)
        if (res.status === 200) {
            const { data } = res.data || { data: { data: [], total: 0 } };
            dispatch(getSubadminPermisionsSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getSubAdminPermissionFailure())
        return { success: false, message: message, data: [] }
    }
}

export const actionUpdateUserStatus = (data) => async (dispatch) => {
    dispatch(updateUserStatusBegins());
    try {
        const res = await api.put(`/admin/user-status-change`, data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'User status updated successfully' }
            dispatch(updateUserStatusSuccess(data))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateUserStatusFailure())
        return { success: false, message: message }
    }
}

// MEMBERSHIP UPDATE
export const actionChangeMembership = (data) => async (dispatch) => {
    dispatch(updateMembershipBegins());
    try {
        const res = await api.put(`/admin/membership-change`, data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'User status updated successfully' }
            dispatch(updateMembershipSuccess(data))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateMembershipFailure())
        return { success: false, message: message }
    }
}

// get customer details by id
export const actionGetCustomerDetailsById = (id) => async (dispatch) => {
    dispatch(getCustomerDetailsByIdBegins());
    try {
        const res = await api.get(`/admin/customer/${id}`)
        if (res.status === 200) {
            const { data } = res.data || { data: { data: [], total: 0 } };
            dispatch(getCustomerDetailsByIdSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getCustomerDetailsByIdFailure())
        return { success: false, message: message, data: [] }
    }
}

// assign sales user to customer
export const actionAssignSalesUserToCustomer = (data) => async (dispatch) => {
    dispatch(assignSalesUsertoCustomersBegins());
    try {
        const res = await api.post(`/admin/customer-salesuser-assign`, data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Sales user assigned successfully' }
            dispatch(assignSalesUsertoCustomersSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(assignSalesUsertoCustomersFailure())
        return { success: false, message: message }
    }
}

// GET SUB USERS LIST
export const actionGetSubUserList = (data) => async (dispatch) => {
    dispatch(getSubUsersListBegins());
    try {
        const res = await api.get(`/admin/customer-sub-users`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } }
            dispatch(getSubUsersListSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getSubUsersListFails())
        return { success: false, message: message, data: [] }
    }
}


// POST SUB USERS LIST
export const actionPostSubUserList = (data) => async (dispatch) => {
    dispatch(postSubUsersListBegins());
    try {
        const res = await api.post(`/admin/sub-user`, data)
        if (res.status === 200) {
            const { user_id } = res?.data || {};
            const { message } = res?.data || { message: 'post user assigned successfully' }
            dispatch(postSubUsersListSuccess(data));
            return { success: true, user_id: user_id, message: message };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(postSubUsersListFails());
        return { success: false, message: message };
    }
}



//GET CUSTOMER OFFER LIST
export const actionGetOfferList = (data) => async (dispatch) => {
    dispatch(getCustomerOfferListBegins());
    try {
        const res = await api.get(`/admin/customer-promotions`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getCustomerOfferListSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getCustomerOfferListFails())
        return { success: false, message: message, data: [] }
    }
}

//GET CUSTOMER CART LIST 
export const actionGetCustomerCartList = (data) => async (dispatch) => {
    dispatch(getCustomerCartListBegins());
    try {
        const res = await api.get(`/admin/customer-cart`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getCustomerCartListSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getCustomerCartListFailure())
        return { success: false, message: message, data: [] }
    }
}

// GET ORDER RATINGS AND FEEDBACK LIST
export const actionOrderRatingsAndFeedBack = (data) => async (dispatch) => {
    dispatch(getOrderRatingsAndFeedbackBegins());
    try {
        const res = await api.get(`/admin/customer-order-ratings`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getOrderRatingsAndFeedbackSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getOrderRatingsAndFeedbackFails())
        return { success: false, message: message, data: [] }
    }
}

// get sub admin details by id
export const actionSubAdminDetails = (id) => async (dispatch) => {
    dispatch(getSubAdminDetailsByIdBegins());
    try {
        const res = await api.get(`/admin/subadmin/${id}`)
        if (res.status === 200) {
            const { data } = res.data || { data: { data: [], total: 0 } };
            dispatch(getSubAdminDetailsByIdSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getSubAdminDetailsByIdFailure())
        return { success: false, message: message, data: [] }
    }
}

// GET SALES USER DETAILS BY ID
export const actionGetSalesUserDetailsById = (id, dateFilter) => async (dispatch) => {
    dispatch(getSalesUserDetailsByIdBegins());
    try {
        const res = await api.get(`/admin/salesuser/${id}`, { params: { sales_history_type: dateFilter?.sales_history_type, review_status_type: dateFilter?.review_status_type } })
        if (res.status === 200) {
            const { data } = res.data || { data: { data: [], total: 0 } };
            dispatch(getSalesUserDetailsByIdSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getSalesUserDetailsByIdFails())
        return { success: false, message: message, data: [] }
    }
}

// GET CUSTOMER WISHLIST LIST
export const actionGetCustomerWishlist = (data) => async (dispatch) => {
    dispatch(getCustomerWishlistBegins());
    try {
        const res = await api.get(`/admin/customer-product-wishlist`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getCustomerWishlistSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getCustomerWishlistFails())
        return { success: false, message: message, data: [] }
    }
}

// GET CUSTOMER ORDERS LIST
export const actionGetCustomerOrdersList = (data) => async (dispatch) => {
    dispatch(getCustomerOrdersListBegins());
    try {
        const res = await api.get(`/admin/customer-orders`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getCustomerOrdersListSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getCustomerOrdersListFails())
        return { success: false, message: message, data: [] }
    }
}

// GET CUSTOMER ORDERS LIST
export const actionGetInterestedProductsList = (data) => async (dispatch) => {
    dispatch(getInterestedProductsListBegins());
    try {
        const res = await api.get(`/admin/customer-product-interest`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getInterestedProductsListSuccess(data))
            return { success: true, message: 'success', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getInterestedProductsListFails())
        return { success: false, message: message, data: [] }
    }
}

//UPLOAD PROFILE IMAGE
export const actionUploadProfileImage = (data) => async (dispatch) => {
    dispatch(uploadProfileImageBegins());
    try {
        const res = await api.post('/profile-pic-upload', data)
        if (res.status === 200) {
            dispatch(uploadProfileImageSuccess())
            return { success: true, message: 'Profile image updated successfully' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(uploadProfileImageFailure())
        return { success: false, message: message }
    }
}

// CUSTOMER EMAIL UPDATE AND INVITE
export const actionUpdateEmailAndInviteCustomer = (data) => async (dispatch) => {
    dispatch(updateEmailAndInviteCustomerBegins());
    try {
        const res = await api.put('/admin/invite/update-customer', data)
        if (res.status === 200) {
            dispatch(updateEmailAndInviteCustomerSuccess(data))
            return { success: true, message: 'Email Updated and Invited Customer Successfully' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateEmailAndInviteCustomerFailure())
        return { success: false, message: message }
    }
}

//CUSTOMER INVITE AGAIN
export const actionCustomerInviteAgain = (id) => async (dispatch) => {
    dispatch(customerInviteAgainBegins());
    try {
        const res = await api.get(`/admin/invite-again/${id}`)
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(customerInviteAgainSuccess())
            return { success: true, message: 'Customer Invited successfully', data: data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(customerInviteAgainFailure())
        return { success: false, message: message, data: [] }
    }
}


// GET ASSIGN PRODUCTS LIST
export const actionGetAssignProductsList = (data) => async (dispatch) => {
    dispatch(getAssignProductsListBegins());
    try {
        const res = await api.get(`/admin/customer-product-names`, { params: data })
        if (res.status === 200) {
            const { data } = res || { data: { data: [], total: 0 } };
            dispatch(getAssignProductsListSuccess(data))
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went wrong" }
        dispatch(getAssignProductsListFailure())
        return { success: false, message: message, data: [] }
    }
}

//ASSIGN PRODUCTS - CHANGE CUSTOMER PRODUCT NAME
export const actionChangeCustomerProductName = (data) => async (dispatch) => {
    dispatch(changeCustomerProductNameBegins());
    try {
        const res = await api.post('/admin/customer/product/assign', data)
        if (res.status === 200) {
            dispatch(changeCustomerProductNameSuccess())
            return { success: true, message: 'Product Name Changed successfully' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(changeCustomerProductNameFailure())
        return { success: false, message: message }
    }
}

// NEW PRODUCT NOTIFICATION SETTINGS
export const actionNewProductNotificationSettings = (data) => async (dispatch) => {
    dispatch(newProductNotificationSettingsBegins());
    try {
        const res = await api.post('/admin/customer/new-product-notification-settings', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Product notification settings updated successfully' }
            dispatch(newProductNotificationSettingsSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(newProductNotificationSettingsFailure())
        return { success: false, message: message }
    }
}

//  ADDITIONAL NOTIFICATION SETTINGS
export const actionAdditionalNotificationSettings = (data) => async (dispatch) => {
    dispatch(additionalNotificationSettingsBegins());
    try {
        const res = await api.post('/admin/customer/additional-notification-settings', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Additional notification settings updated successfully' }
            dispatch(additionalNotificationSettingsSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(additionalNotificationSettingsFailure())
        return { success: false, message: message }
    }
}

// DELETE ADDITIONAL NOTIFICATION SETTINGS
export const actionDeleteAdditionalNotificationSettings = (id) => async (dispatch) => {
    dispatch(deleteAdditionalNotificationSettingsBegins());
    try {
        const res = await api.delete(`/admin/customer/additional-notification-settings/${id}`);
        const { message } = res?.data;
        dispatch(deleteAdditionalNotificationSettingsSuccess({ id }));
        if (res.status === 200) {
            return { success: true, message };
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(deleteAdditionalNotificationSettingsFailure());
        return { success: false, message: message };
    }
}

// GET SALES USERS API
export const actionGetCustomerSalesUSers = (email) => async (dispatch) => {
    dispatch(getCustomerSalesUserBegins());
    try {
        const res = await api.get(`/admin/get-salesusers`, { params: { email: email } })
        if (res.status === 200) {
            // const { response } = res.data || { response: { data: [], total: 0 } };
            dispatch(getCustomerSalesUserSuccess(res.data))
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getCustomerSalesUserFailure())
        return { success: false, message: message }
    }
}

// GET USERS BY EMAILS API
export const actionGetCustomersByEmails = (emails) => async (dispatch) => {
    dispatch(getCustomerSalesUserBegins());
    try {
        const res = await api.get(`/admin/get-customers-by-email`, { params: { email: emails } })
        if (res.status === 200) {
            // const { response } = res.data || { response: { data: [], total: 0 } };
            // dispatch(getCustomerSalesUserSuccess(res.data))
            return { success: true, message: 'success', data: res.data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getCustomerSalesUserFailure())
        return { success: false, message: message }
    }
}

// DELETE PROFILE IMAGE
export const actionDeleteProfileImage = (id) => async (dispatch) => {
    dispatch(deleteProfileImageBegins());
    try {
        const res = await api.delete(`/profile-pic-delete`, { params: id });
        const { message } = res?.data;
        dispatch(deleteProfileImageSuccess({ id }));
        if (res.status === 200) {
            return { success: true, message };
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(deleteProfileImageFailure());
        return { success: false, message: message };
    }
}

// DELETE SALES USERS CUSTOMERS
export const actionDeleteSalesUserCustomer = (payload) => async (dispatch) => {
    dispatch(deleteSalesUserCustomerBegins());
    try {
        const res = await api.delete(`/admin/sales-user-customer-delete`, { params: payload });
        const { message } = res?.data;
        dispatch(deleteSalesUserCustomerSuccess());
        if (res.status === 200) {
            return { success: true, message };
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(deleteSalesUserCustomerFailure());
        return { success: false, message: message };
    }
}