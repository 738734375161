import React, { useEffect, useState } from 'react'
import { Badge, Col, Row, Placeholder } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Avatar, Button, Typography, DropDown, SnackMessages } from '..'
import { actionChangeCustomerStatus, actionUpdateCreditHold } from '../../actions/Dashboard/Orders'
import { ChatIcon, CheckTickIcon, CreditHoldIcon, CreditNoteIcon, CreditPaidIcon, DeliveredIcon, EditPencilIcon, EmailIcon, LocationIcon, PendingIcon, PhoneIcon, ProcessingIcon, ProfileIcon, ShippingIcon } from '../SVGIcons'
import "./index.scss"

export const CustomerDetails = ({ status, customerDetails, orderId, setChatVisibility, loader, type, url, updateStatus }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showSuccessMessage } = SnackMessages();
    const { customerStatus } = customerDetails || { customerStatus: '', customerId: '' }
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [isCreditHoldDropdownOpen, setCreditHoldDropdownOpen] = useState(false)
    const [selectedIndex, setselectedIndex] = useState(-1)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [creditStatus, setCreditStatus] = useState(orderId?.credit_hold)
    const [customerStatusUpdate, setCustomerStatusUpdate] = useState(orderId?.customer_status)
    const [selectedCreditHoldIndex, setselectedCreditHoldIndex] = useState(-1)
    const [customerStatusList,] = useState(
        [
            { id: 0, label: 'Processing', icon: ProcessingIcon, value: "Processing" },
            { id: 1, label: 'MO', icon: PendingIcon, value: "MO" },
            { id: 2, label: 'Approval', icon: CheckTickIcon, value: "Approval" },
            { id: 3, label: 'Shipped', icon: ShippingIcon, value: "Shipped" },
            // { id: 4, label: 'Delivered', icon: DeliveredIcon, value: "Delivered" },
        ]
    )
    const [creditStatusList,] = useState(
        [
            { id: 0, label: 'Paid', icon: CreditPaidIcon, value: "Paid" },
            { id: 1, label: 'Hold', icon: CreditHoldIcon, value: "Hold" }
        ]
    )

    const renderCurrentProcess = (status) => {
        switch (status) {
            case 'Processing':
                return <Badge className='badge-status d-flex align-items-center justify-content-center purple' bg=''><ProcessingIcon className="me-2" />Processing</Badge>
            case 'MO':
                return <Badge className='badge-status d-flex align-items-center justify-content-center orange' bg=''><PendingIcon className="me-2" />MO</Badge>
            case 'Approval':
                return <Badge className='badge-status d-flex align-items-center justify-content-center cyan' bg=''><CheckTickIcon className="me-2" />Approval</Badge>
            case 'Shipped':
                return <Badge className='badge-status d-flex align-items-center justify-content-center blue' bg=''><ShippingIcon className="me-2" />Shipped</Badge>
            case 'Delivered':
                return <Badge className='badge-status d-flex align-items-center justify-content-center success' bg=''><DeliveredIcon className="me-2" />Delivered</Badge>
            default:
                return "";
        }
    }

    const renderCurrentCreditHoldStatus = (creditStatus) => {
        switch (creditStatus) {
            case 'Paid':
                return <Badge className='badge-status d-flex align-items-center justify-content-center red-pink' bg=''><CreditPaidIcon className="me-1" />Paid</Badge>
            case 'Hold':
                return <Badge className='badge-status d-flex align-items-center justify-content-center red-pink mr-lg-38' bg=''><CreditHoldIcon className="me-1" />Hold</Badge>
            default:
                return "";
        }
    }

    const handleDropDown = (eventKey, event) => {
        setCustomerStatusUpdate(eventKey)
        const newCustomerStatusList = [...customerStatusList]
        const index = newCustomerStatusList.findIndex(item => item.value === eventKey)
        setselectedIndex(index)

    }

    const updateCustomerStatus = async () => {
        setButtonLoader(true)
        const newCustomerStatusList = [...customerStatusList]
        const status = newCustomerStatusList[selectedIndex]?.value;
        const res = await dispatch(actionChangeCustomerStatus({ orderId: orderId?.id, status }))
        updateStatus(orderId?.id, status)
        if (res.success) {
            showSuccessMessage(res.message)
            setButtonLoader(false)
        } else {
            showErrorMessage(res.message)
        }
        setDropdownOpen(false)
    }

    const handleCancelStatusChange = () => {
        const index = customerStatusList.findIndex(item => item.value === customerStatus)
        setselectedIndex(index)
        setDropdownOpen(false)
        setCustomerStatusUpdate(orderId?.customer_status)
    }

    const updateCreditHoldStatus = async () => {
        setButtonLoader(true)
        const id = orderId?.id
        const newCreditHoldStatusList = [...creditStatusList]
        const credit_hold = newCreditHoldStatusList[selectedCreditHoldIndex]?.value;
        const payload = { credit_hold: credit_hold }
        const res = await dispatch(actionUpdateCreditHold(id, payload))
        if (res.success) {
            showSuccessMessage(res.message)
            setButtonLoader(false)
        } else {
            showErrorMessage(res.message)
        }
        setCreditHoldDropdownOpen(false)
    }

    const handleCreditHoldDropDown = (eventKey, event) => {
        setCreditStatus(eventKey)
        const newCreditHoldStatusList = [...creditStatusList]
        const index = newCreditHoldStatusList.findIndex(item => item.value === eventKey)
        setselectedCreditHoldIndex(index)
    }

    const handleCancelCreditHoldStatusChange = () => {
        const index = creditStatusList.findIndex(item => item.value === orderId?.credit_hold)
        setselectedCreditHoldIndex(index)
        setCreditHoldDropdownOpen(false)
        setCreditStatus(orderId?.credit_hold)
    }

    const toggleChangeStatusDropdown = () => {
        setDropdownOpen(true)
        setCreditHoldDropdownOpen(false)
    }

    const toggleCreditStatusDropdown = () => {
        setCreditHoldDropdownOpen(true)
        setDropdownOpen(false)
    }

    const CustomStatusButtons = () => (
        <div className="d-flex flex-row p-1 bg-white gap-2">
            <Button variant="outline-primary" label='Cancel' customClass="btn-sm" onClick={handleCancelStatusChange} />
            <Button label="Update" disabled={buttonLoader} variant="primary" customClass="btn-sm" onClick={updateCustomerStatus} />
        </div>
    )

    const CustomCreditHoldStatusButtons = () => (
        <div className="d-flex flex-row p-1 bg-white gap-2">
            <Button variant="outline-primary" label='Cancel' customClass="btn-sm" onClick={handleCancelCreditHoldStatusChange} />
            <Button label="Update" disabled={buttonLoader} variant="primary" customClass="btn-sm" onClick={updateCreditHoldStatus} />
        </div>
    )

    useEffect(() => {
        if (customerStatus) {
            const index = customerStatusList.findIndex(item => item.value === customerStatus)
            setselectedIndex(index)
        }
    }, [customerStatus, customerStatusList])

    useEffect(() => {
        setCreditStatus(orderId?.credit_hold)
        setCustomerStatusUpdate(orderId?.customer_status)
    }, [orderId])


    return (
        <>
            {loader ? <CustomLoader /> :
                <div className='customer-details bg-white rounded-4 shadow-card p-3'>
                    <Row className='justify-content-between align-items-center title-container flex-wrap'>
                        <Col xs={'auto'} className="d-flex gap-2 title align-items-center">
                            <ProfileIcon /><Typography variant={'text-custom'} margin="mb-0" fontSize="text-15" fontWeight="font-semibold" label={"Customer Details"} />
                        </Col>
                        <Col xs={'auto'} className="d-flex chat-icon align-items-center justify-content-end flex-fill">
                            <Button icon={<ChatIcon />} variant="primary-rounded" onClick={() => setChatVisibility(true)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr className='card-divider' />
                        </Col>
                    </Row>
                    <div className='d-flex align-items-center gap-2 gap-sm-3 flex-wrap'>
                        <Avatar variant={'profile-image-no-border'} membership={customerDetails?.membership !== null || customerDetails?.membership !== undefined ? customerDetails?.membership?.toLowerCase() : ""} bsPrefix src={customerDetails?.profile_image !== undefined || customerDetails?.src !== undefined || url !== undefined ?  (customerDetails?.profile_image || `${url}/original${customerDetails?.src}`) : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200"} />
                        <div className='d-flex flex-column gap-1'>
                            <div className='d-flex gap-2'>
                                <Typography variant={'text-custom'} margin="mb-0" color={"color-dark01"} fontSize="text-15" fontWeight="font-semibold" label={customerDetails?.customerName || ''} />
                            </div>
                            <div className='d-flex gap-2'>
                                <Typography variant={'text-custom'} margin="mb-0" color={"color-primary"} fontSize="text-12" fontWeight="font-bold" label={customerDetails?.customerCardCode || 'Nil'} />
                            </div>
                            <div className='d-flex flex-row d-flex gap-2 align-items-center'>
                                <EmailIcon className="icon-16" />
                                <Typography variant={'text-custom'} margin="mb-0" color={"color-info"} fontSize="text-13" fontWeight="font-semibold" label={customerDetails?.email || 'Nil'} />
                            </div>
                            <div className='d-flex gap-2 align-items-center'>
                                {/* <PhoneIcon className="icon-16" /> */}
                                {/* <Typography variant={'text-custom'} margin="mb-0" color={"color-info"} fontSize="text-13" fontWeight="font-semibold" label={customerDetails?.phone || 'Nil'} /> */}
                            </div>
                            <div className='d-flex gap-2 align-items-center'>
                                <ProfileIcon className="icon-16" />
                                <Typography variant={'text-custom'} margin="mb-0" color={"color-gray03"} fontSize="text-13" fontWeight="font-semibold" label={customerDetails?.company || "Nil"} />
                            </div>
                        </div>
                    </div>
                    {/* <div className='d-flex gap-2 mt-3 align-items-center'>
                        <LocationIcon className="icon-16" />
                        <Typography variant={'text-custom'} margin="mb-0" color={"color-gray03"} fontSize="text-13" fontWeight="font-semibold" label={customerDetails?.adress || 'Nil'} />
                    </div> */}
                    {type === 'open-details' && <div>
                        <Row>
                            <Col xs={12}>
                                <hr className='card-divider' />
                            </Col>
                        </Row>
                        <div className='d-flex gap-2 align-items-center flex-wrap'>
                            <span className='status-label'>
                                <Typography variant={'text-custom'} margin="mb-0 me-2" color={"color-gray"} fontSize="text-12" fontWeight="font-semibold" label={"Customer Status"} />
                            </span>
                            <div className='d-flex align-items-center justify-content-between gap-2 flex-fill flex-wrap'>
                                {renderCurrentProcess(customerStatusUpdate)}
                                <div className='w-auto d-flex'>
                                    <DropDown isOpen={isDropdownOpen} onToggle={toggleChangeStatusDropdown} CustomComponent={CustomStatusButtons} handleDropDown={handleDropDown} icon={<EditPencilIcon />} options={customerStatusList} activeIndex={selectedIndex} variant={"button-dropdown-with-option-icon"} label={"Change Status"} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center flex-wrap mt-2'>
                            <span className='status-label'>
                                <Typography variant={'text-custom'} margin="mb-0 me-2" color={"color-gray"} fontSize="text-12" fontWeight="font-semibold" label={"Credit Status"} />
                            </span>
                            <div className='d-flex align-items-center justify-content-between gap-2 flex-fill flex-wrap'>
                                {renderCurrentCreditHoldStatus(creditStatus)}
                                <div className='w-auto d-flex creditHold-dropdown'>
                                    <DropDown isOpen={isCreditHoldDropdownOpen} onToggle={toggleCreditStatusDropdown} CustomComponent={CustomCreditHoldStatusButtons} handleDropDown={handleCreditHoldDropDown} icon={<CreditNoteIcon />} options={creditStatusList} activeIndex={selectedCreditHoldIndex} variant={"button-dropdown-with-option-icon"} label={"Change Credit"} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
        </>
    )
}

const CustomLoader = () => (
    <div className='custom-loader bg-white rounded-4 shadow-card p-3'>
        <Row className='justify-content-between align-items-center title-container flex-wrap'>
            <Col xs={'auto'} className="d-flex gap-2 title align-items-center">
                <ProfileIcon /><Typography variant={'text-custom'} margin="mb-0" fontSize="text-15" fontWeight="font-semibold" label={"Customer Details"} />
            </Col>
            <Col xs={'auto'} className="d-flex chat-icon align-items-center justify-content-end flex-fill">
                <Button icon={<ChatIcon />} variant="primary-rounded" />
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <hr className='card-divider' />
            </Col>
        </Row>
        <div className='d-flex align-items-center gap-2 gap-sm-3 flex-wrap'>
            <Placeholder className="avatar-loader" animation="glow">
                <Placeholder xs={12} />
            </Placeholder>
            <div className='d-flex flex-column gap-1 w-60'>
                <div className='d-flex gap-2'>
                    <Placeholder className="name-loader" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>

                </div>
                <div className='d-flex gap-2'>
                    <Placeholder className="sub-name-loader" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>     </div>
                <div className='d-flex flex-row d-flex gap-2 align-items-center'>
                    <EmailIcon className="icon-16" />
                    <Placeholder className="small-loader" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>

                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <PhoneIcon className="icon-16" />
                    <Placeholder className="small-loader" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <ProfileIcon className="icon-16" />
                    <Placeholder className="small-loader" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </div>
            </div>
        </div>
        <div className='d-flex gap-2 mt-3 align-items-center'>
            <LocationIcon className="icon-16" />
            <Placeholder className="small-loader" animation="glow">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
            </Placeholder>
        </div>
        <Row>
            <Col xs={12}>
                <hr className='card-divider' />
            </Col>
        </Row>
        <div className='d-flex gap-2 align-items-center justify-content-between flex-wrap'>
            <div className='d-flex align-items-center flex-fill flex-wrap'>
                <Placeholder className="name-loader" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>

            </div>
            <div className='w-auto flex-fill d-flex justify-content-end'>
                <Placeholder className="name-loader" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
            </div>
        </div>
    </div>)