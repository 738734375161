import { CREATE_PRODUCT_PERMISSIONS_BEGINS, CREATE_PRODUCT_PERMISSIONS_FAILURE, CREATE_PRODUCT_PERMISSIONS_SUCCESS, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS, GET_PRODUCT_DETAILS_BEGINS, GET_PRODUCT_DETAILS_FAILURE, GET_PRODUCT_DETAILS_SUCCESS, GET_PRODUCT_LIST_BEGINS, GET_PRODUCT_LIST_FAILURE, GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_ORDERS_LIST_BEGINS, GET_PRODUCT_ORDERS_LIST_FAILURE, GET_PRODUCT_ORDERS_LIST_SUCCESS, GET_PRODUCT_PAGINATION_SUCCESS, GET_PRODUCT_SEARCH_SUCCESS, GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS, GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE, GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS, GET_SELECTED_PRODUCT_FILTERS_SUCCESS, GET_WISHLIST_ADDED_USERS_BEGINS, GET_WISHLIST_ADDED_USERS_FAILURE, GET_WISHLIST_ADDED_USERS_SUCCESS, PRODUCT_FILTER_BEGINS, PRODUCT_FILTER_FAILURE, PRODUCT_FILTER_SUCCESS, UPDATE_PRODUCT_SORT_SUCCESS,PRODUCT_CHECKBOX_BEGINS, PRODUCT_CHECKBOX_SUCCESS, PRODUCT_CHECKBOX_FAILURE } from "../constants";


const INITIAL_STATE = {
    isTableLoading: false,
    productsSortOptions: [
        // { id: "1", value: "most-purchased", label: "Most Purchased", isActive: false, },
        { id: "4", value: "alphabetical-ascending", label: "Alphabetical - Ascending", isActive: false },
        { id: "5", value: "alphabetical-descending", label: "Alphabetical - Descending", isActive: false },
        { id: "2", value: "newest-first", label: "Newest First", isActive: false },
        // { id: "3", value: "best-offer", label: "Best Offers", isActive: false },
    ],
    products: [{
        data: [],
        total: 0
    }],
    productOrders: [{
        data: [],
        total: 0
    }],
    ratingsAndFeedbacks: [{
        data: [],
        total: 0
    }],
    productsDetails: {},
    isLoadProductDetails: false,
    productFilter: [],
    productWishedUsers: [],
    usersAddedProductInCart: [],
    usersAddedProductAsInterested: [],
    activeProductSortOption: { id: "1", value: "alphabetical-ascending", label: "alphabetical-ascending", isActive: true },
    selectedProductFilter: [],
    productSearch: "",
    productPagination: 0,
    products: [{
        data: [],
        total: 0
    }],
    loading: false,
    data: null,
    error: null
};

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_LIST_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_PRODUCT_LIST_SUCCESS:
            return { ...state, isTableLoading: false, products: action.data };
        case GET_PRODUCT_LIST_FAILURE:
            return { ...state, isTableLoading: false };

        case GET_PRODUCT_DETAILS_BEGINS:
            return { ...state, isLoadProductDetails: true };
        case GET_PRODUCT_DETAILS_SUCCESS:
            return { ...state, isLoadProductDetails: false, productsDetails: action.data };
        case GET_PRODUCT_DETAILS_FAILURE:
            return { ...state, isLoadProductDetails: false };

        case PRODUCT_FILTER_BEGINS:
            return { ...state, isTableLoading: true };
        case PRODUCT_FILTER_SUCCESS:
            return { ...state, isTableLoading: false, productFilter: action.data };
        case PRODUCT_FILTER_FAILURE:
            return { ...state, isTableLoading: false };

        case CREATE_PRODUCT_PERMISSIONS_BEGINS:
            return { ...state, isLoadProductDetails: true };
        case CREATE_PRODUCT_PERMISSIONS_SUCCESS:
            return { ...state, isLoadProductDetails: false };
        case CREATE_PRODUCT_PERMISSIONS_FAILURE:
            return { ...state, isLoadProductDetails: false };

        case GET_WISHLIST_ADDED_USERS_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_WISHLIST_ADDED_USERS_SUCCESS:
            return { ...state, isTableLoading: false, productWishedUsers: action.data };
        case GET_WISHLIST_ADDED_USERS_FAILURE:
            return { ...state, isTableLoading: false };

        case UPDATE_PRODUCT_SORT_SUCCESS:
            return { ...state, activeProductSortOption: action.data };

        case GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS:
            return { ...state, isTableLoading: false, usersAddedProductInCart: action.data };
        case GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE:
            return { ...state, isTableLoading: false };

        case GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS:
            return { ...state, isTableLoading: false, usersAddedProductAsInterested: action.data };
        case GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE:
            return { ...state, isTableLoading: false };

        case GET_PRODUCT_ORDERS_LIST_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_PRODUCT_ORDERS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, productOrders: action.data };
        case GET_PRODUCT_ORDERS_LIST_FAILURE:
            return { ...state, isTableLoading: false };

        case GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS:
            return { ...state, isTableLoading: true };
        case GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, ratingsAndFeedbacks: action.data };
        case GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE:
            return { ...state, isTableLoading: false };

        case GET_SELECTED_PRODUCT_FILTERS_SUCCESS:
            return { ...state, selectedProductFilter: action.data };

        case GET_PRODUCT_SEARCH_SUCCESS:
            return { ...state, productSearch: action.data };

        case GET_PRODUCT_PAGINATION_SUCCESS:
            return { ...state, productPagination: action.data };

        case PRODUCT_CHECKBOX_BEGINS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case PRODUCT_CHECKBOX_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };
        case PRODUCT_CHECKBOX_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

export default productReducer;