import React from 'react'
import { Col, Placeholder, Row } from 'react-bootstrap'
import { Typography } from '..'
import { ShippingIcon } from '../SVGIcons'
import "./index.scss"

export const ShippingDetails = ({ loader, customButton, shippingDetails }) => {
  return (
    <div className='shipping-details bg-white rounded-4 shadow-card p-3'>
      <div className="d-flex gap-2 title align-items-center justify-content-between flex-wrap">
        <div className='d-flex gap-2 title align-items-center'>
          <ShippingIcon /><Typography variant={'text-custom'} margin="mb-0" fontSize="text-15" fontWeight="font-semibold" label={"Shipping "} />
        </div>
        <div className='flex-fill d-flex justify-content-end'>{customButton}</div>
      </div>
      <Row>
        <Col xs={12}>
          <hr className='card-divider' />
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col xs={12} >
          <Typography variant={'text-custom'} margin="mb-0" fontSize="text-13" fontWeight="font-semibold" color={'color-dark01'} label={"Shipping Details"} />
        </Col>
        {!loader ? <Col xs={12} >
          <Typography variant={'text-custom'} margin="mb-0" fontSize="text-13" color={'color-gray03'} fontWeight="font-semibold" label={shippingDetails?.shippingAddress || 'Nil'} />
        </Col> :
          <Col xs={12}>
            <Placeholder animation="glow">
              <Placeholder className="rounded-3" xs={12} />
              <Placeholder className="rounded-3" xs={12} />
              <Placeholder className="rounded-3" xs={12} />
            </Placeholder>
          </Col>}
      </Row>
      <Row>
        <Col xs={12} >
          <Typography variant={'text-custom'} margin="mb-0" fontSize="text-13" fontWeight="font-semibold" color={'color-dark01'} label={"Billing Address"} />
        </Col>
        {!loader ? <Col xs={12} >
          <Typography variant={'text-custom'} margin="mb-0" fontSize="text-13" color={'color-gray03'} fontWeight="font-semibold" label={shippingDetails?.billingAddress || 'Nil'} />
        </Col> :
          <Col xs={12}>
            <Placeholder animation="glow">
              <Placeholder className="rounded-3" xs={12} />
              <Placeholder className="rounded-3" xs={12} />
              <Placeholder className="rounded-3" xs={12} />
            </Placeholder>
          </Col>}
      </Row>


    </div>
  )
}
