import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  updateDoc,
  doc,
} from "@firebase/firestore";
import { SnackMessages } from "../../../components";
import { auth, db } from "../../../firebase";
import {
  actionGetCustomerSalesUSers,
  actionGetCustomersByEmails,
} from "../../../actions/Dashboard/UserManagement";
import { Card, Col, Placeholder, Row } from "react-bootstrap";
import ChatBody from "./ChatBody";
import ChatHead from "./ChatHead";
import "./index.scss";
import moment from "moment";
import { Avatars } from "../../../components/Avatars";

const Chat = () => {
  const [chat, setChat] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [userId, setUserId] = useState();
  const [customer, setCustomer] = useState(null);
  const [realChat, setRealChat] = useState(null);
  const [initialChat, setInitialChat] = useState(null);
  const [isChatLoading, setChatLoading] = useState(false);
  const [isChatBodyLoading, setChatBodyLoading] = useState(true);
  const [chatBodyVisible, setChatBodyVisible] = useState(true);
  let dispatch = useDispatch();

  const getUserChat = async (e) => {
    const userResponse = query(
      collection(db, "users"),
      where("email", "==", e.email)
    );
    const userData = await getDocs(userResponse);
    let id = "";
    userData.forEach((_data) => {
      id = _data.id;
    });
    setCustomer(e);
    const response = query(
      collection(db, "users", id, "messages"),
      orderBy("createdOn", "asc")
    );
    const data = await getDocs(response);
    const dataUsers = [];
    data.forEach((_data) => {
      dataUsers.push(_data.data());
    });
    setChatHistory(dataUsers);
  };

  const fetchPost = async () => {
    setChatLoading(true);
    const getUserEmail = JSON.parse(localStorage.getItem("userData"));
    const users = query(
      collection(db, "users"),
      where("email", "==", getUserEmail)
    );
    const allUsers = await getDocs(users);
    let userId = "";
    allUsers.forEach((doc) => {
      userId = doc.id;
    });
    setUserId(userId);
    const response = query(collection(db, "users"));
    const data = await getDocs(response);
    const dataUsers = [];
    data.forEach((_data) => {
      dataUsers.push(_data.data());
    });
    const q = query(collection(db, "users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const snapData = [];
      querySnapshot.forEach((doc) => {
        snapData.push(doc.data());
        if (doc.data().email == customer?.email) {
          getUserChat(customer);
        }
      });
    });
    const res = await dispatch(
      actionGetCustomersByEmails(dataUsers.map((e) => e.email))
    );
    const updateList = dataUsers
      .map((objB) => {
        const matchingObjA = res?.data?.find(
          (objA) => objA.email === objB.email
        );
        if (matchingObjA) {
          return { ...objB, image: matchingObjA.profile_image };
        } else {
          return objB;
        }
      })
      .filter((e) => e?.lastMessageTime !== undefined)
      .sort(
        (a, b) =>
          moment(b?.lastMessageTime?.toDate()) - moment(a?.lastMessageTime?.toDate())
      );

    setChat(updateList);
    handleChat(updateList[0]);
    setChatLoading(false);
    setInitialChat(updateList);
  };

  const handleChat = (e) => {
    setChatBodyVisible(true);
    getChatUsers(e?.email);
    getUserChat(e);
    setRealChat(e);
    handleMessageRead(e);
  };

  const handleMessageRead = async (e) => {
    const q = query(collection(db, "users"), where("email", "==", e.email));
    const querySnapShot = await getDocs(q);
    const queryData = querySnapShot.docs.map((e) => ({
      id: e.id,
    }));
    console.log(queryData, "queryData");

    // const res = await doc(db, `users/${e.id}`).set({ capital: true })
    queryData.map(async (e) => {
      await updateDoc(doc(db, `users/${e.id}`), {
        isSeen: true,
      });
       setChatBodyLoading(false)
    });
  };
  const CustomLoader = () => (
    // <div className='d-flex justify-content-center align-items-center h-100'>
    //     <Avatars src="/logo.gif" variant={"gif-loader"} />
    // </div>
    <Card className={"h-100 p-3 gap-4"}>
      <Row>
        <Col lg={2}>
          <Placeholder className="avatar-loader h-100" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
        <Col lg={10}>
          <div className="d-flex flex-column">
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Placeholder className="avatar-loader h-100" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
        <Col lg={10}>
          <div className="d-flex flex-column">
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Placeholder className="avatar-loader h-100" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
        <Col lg={10}>
          <div className="d-flex flex-column">
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={11} />
            </Placeholder>
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Placeholder className="avatar-loader h-100" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
        <Col lg={10}>
          <div className="d-flex flex-column">
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={11} />
            </Placeholder>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Placeholder className="avatar-loader h-100" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
        <Col lg={10}>
          <div className="d-flex flex-column">
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={9} />
            </Placeholder>
            <Placeholder className="avatar-loader" animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
          </div>
        </Col>
      </Row>
    </Card>
  );
  const CustomLoaderBody = () =>
    chatBodyVisible && (
      <Card
        className={"h-100 p-3 gap-4 d-flex flex-column justify-content-center"}
      >
        <Placeholder
          className="avatar-loader d-flex justify-content-center"
          animation="glow"
        >
          <Placeholder xs={5} />
        </Placeholder>
        <Placeholder
          className="avatar-loader d-flex justify-content-center"
          animation="glow"
        >
          <Placeholder xs={8} />
        </Placeholder>
        <Placeholder
          className="avatar-loader d-flex justify-content-center"
          animation="glow"
        >
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder
          className="avatar-loader d-flex justify-content-center"
          animation="glow"
        >
          <Placeholder xs={7} />
        </Placeholder>
        <Placeholder
          className="avatar-loader d-flex justify-content-center"
          animation="glow"
        >
          <Placeholder xs={3} />
        </Placeholder>
      </Card>
    );

  const handleSearch = (e) => {
    let filteredChat = initialChat;
    if (e.target.value) {
      filteredChat = [...chat].filter((item) => {
        return item.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
    }
    setChat(filteredChat);
  };

  const getChatUsers = React.useCallback(async (email) => {
    const res = await dispatch(actionGetCustomerSalesUSers(email));
    if (!res.success) {
      const { showErrorMessage } = SnackMessages();
      // showErrorMessage(res.message)
    }
  }, []);

  useEffect(() => {
    fetchPost();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setChatBodyLoading(false);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.title = 'Tab Surfaces Admin - Chats';
  }, [])

  return (
    <div className="chat flex-nowrap flex-fill-row">
      <div className="h-100">
        <Row className="h-100 position-relative">
          <Col lg={5} xxl={4} className={"chat-trigger h-100"}>
            {isChatLoading ? (
              <CustomLoader />
            ) : (
              <ChatHead
                chatUsers={chat}
                handleChat={handleChat}
                customer={customer}
                handleSearch={handleSearch}
              />
            )}
          </Col>
          {chatBodyVisible && (
            <Col lg={7} xxl={8} className={"chat-container h-100"}>
              {isChatBodyLoading ? (
                <CustomLoaderBody />
              ) : (
                <ChatBody
                  chatHistory={chatHistory}
                  userId={userId}
                  customer={customer}
                  getUserChat={getUserChat}
                  fetchPost={fetchPost}
                  handleChatBody={() => setChatBodyVisible(false)}
                />
              )}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default React.memo(Chat);
