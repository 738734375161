import {
    CREATE_PRODUCT_PERMISSIONS_BEGINS, CREATE_PRODUCT_PERMISSIONS_FAILURE, CREATE_PRODUCT_PERMISSIONS_SUCCESS, DELETE_PRODUCT_IMAGE_BEGINS, DELETE_PRODUCT_IMAGE_FAILURE, DELETE_PRODUCT_IMAGE_SUCCESS, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE, GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE, GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS, GET_PRODUCT_DETAILS_BEGINS, GET_PRODUCT_DETAILS_FAILURE, GET_PRODUCT_DETAILS_SUCCESS, GET_PRODUCT_LIST_BEGINS, GET_PRODUCT_LIST_FAILURE, GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_ORDERS_LIST_BEGINS, GET_PRODUCT_ORDERS_LIST_FAILURE, GET_PRODUCT_ORDERS_LIST_SUCCESS, GET_PRODUCT_PAGINATION_SUCCESS, GET_PRODUCT_SEARCH_SUCCESS, GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS, GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE, GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS, GET_SELECTED_PRODUCT_FILTERS_SUCCESS, GET_WISHLISTS_BEGINS, GET_WISHLISTS_FAILURE, GET_WISHLISTS_SUCCESS, GET_WISHLIST_ADDED_USERS_BEGINS, GET_WISHLIST_ADDED_USERS_FAILURE, GET_WISHLIST_ADDED_USERS_SUCCESS, PRODUCT_FILTER_BEGINS, PRODUCT_FILTER_FAILURE,
    PRODUCT_FILTER_SUCCESS, UPDATE_PRODUCT_SORT_SUCCESS, UPDATE_PRODUCT_VISIBILITY_STATUS_BEGINS, UPDATE_PRODUCT_VISIBILITY_STATUS_FAILURE, UPDATE_PRODUCT_VISIBILITY_STATUS_SUCCESS, UPLOAD_PRODUCT_IMAGE_BEGINS, UPLOAD_PRODUCT_IMAGE_FAILURE, UPLOAD_PRODUCT_IMAGE_SUCCESS,PRODUCT_CHECKBOX_BEGINS,PRODUCT_CHECKBOX_SUCCESS, PRODUCT_CHECKBOX_FAILURE, DELETE_PRODUCT_BEGINS, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE
} from "../../../constants";
import api from "../../../utils/axios";

const getProductListBegins = () => ({ type: GET_PRODUCT_LIST_BEGINS });
const getProductListSuccess = (data) => ({ type: GET_PRODUCT_LIST_SUCCESS, data });
const getProductListFailure = () => ({ type: GET_PRODUCT_LIST_FAILURE });

const getProductDetailsBegins = () => ({ type: GET_PRODUCT_DETAILS_BEGINS })
const getProductDetailsSuccess = (data) => ({ type: GET_PRODUCT_DETAILS_SUCCESS, data })
const getProductDetailsFailure = () => ({ type: GET_PRODUCT_DETAILS_FAILURE })

const productFilterBegins = () => ({ type: PRODUCT_FILTER_BEGINS })
const productFilterSuccess = (data) => ({ type: PRODUCT_FILTER_SUCCESS, data })
const productFilterFailure = () => ({ type: PRODUCT_FILTER_FAILURE })

const updateSortOptions = (data) => ({ type: UPDATE_PRODUCT_SORT_SUCCESS, data })

const createproductPermissionBegins = () => ({ type: CREATE_PRODUCT_PERMISSIONS_BEGINS })
const createproductPermissionSuccess = () => ({ type: CREATE_PRODUCT_PERMISSIONS_SUCCESS })
const createproductPermissionFailure = () => ({ type: CREATE_PRODUCT_PERMISSIONS_FAILURE })

const getWishListsBegins = () => ({ type: GET_WISHLISTS_BEGINS })
const getWishListsSuccess = (data) => ({ type: GET_WISHLISTS_SUCCESS, data })
const getWishListsFailure = () => ({ type: GET_WISHLISTS_FAILURE })

const getWishListAddedUsersBegins = () => ({ type: GET_WISHLIST_ADDED_USERS_BEGINS })
const getWishListAddedUsersSuccess = (data) => ({ type: GET_WISHLIST_ADDED_USERS_SUCCESS, data })
const getWishListAddedUsersFailure = () => ({ type: GET_WISHLIST_ADDED_USERS_FAILURE })

const productVisibilityStatusBegins = () => ({ type: UPDATE_PRODUCT_VISIBILITY_STATUS_BEGINS })
const productVisibilityStatusSuccess = (data) => ({ type: UPDATE_PRODUCT_VISIBILITY_STATUS_SUCCESS, data })
const productVisibilityStatusFailure = () => ({ type: UPDATE_PRODUCT_VISIBILITY_STATUS_FAILURE })

const getProductAddedInCartUsersListBegins = () => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS })
const getProductAddedInCartUsersListSuccess = (data) => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS, data })
const getProductAddedInCartUsersListFailure = () => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE })

const getAddedInterestedUsersListBegins = () => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS })
const getAddedInterestedUsersListSuccess = (data) => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS, data })
const getAddedInterestedUsersListFailure = () => ({ type: GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE })

const getProductOrdresListBegins = () => ({ type: GET_PRODUCT_ORDERS_LIST_BEGINS })
const getProductOrdresListSuccess = (data) => ({ type: GET_PRODUCT_ORDERS_LIST_SUCCESS, data })
const getProductOrdresListFailure = () => ({ type: GET_PRODUCT_ORDERS_LIST_FAILURE })

const getRatingsAndFeedbacksListBegins = () => ({ type: GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS })
const getRatingsAndFeedbacksListSuccess = (data) => ({ type: GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS, data })
const getRatingsAndFeedbacksListFailure = () => ({ type: GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE })

const uploadProductImageBegins = () => ({ type: UPLOAD_PRODUCT_IMAGE_BEGINS })
const uploadProductImageSuccess = (data) => ({ type: UPLOAD_PRODUCT_IMAGE_SUCCESS, data })
const uploadProductImageFailure = () => ({ type: UPLOAD_PRODUCT_IMAGE_FAILURE })

const deleteProductImageBegins = () => ({ type: DELETE_PRODUCT_IMAGE_BEGINS })
const deleteProductImageSuccess = (data) => ({ type: DELETE_PRODUCT_IMAGE_SUCCESS, data })
const deleteProductImageFailure = () => ({ type: DELETE_PRODUCT_IMAGE_FAILURE })

const getSelectedProductFilterSuccess = (data) => ({ type: GET_SELECTED_PRODUCT_FILTERS_SUCCESS, data})

const getProductSearchSuccess = (data) => ({ type: GET_PRODUCT_SEARCH_SUCCESS, data })

const getProductPaginationSuccess = (data) => ({ type: GET_PRODUCT_PAGINATION_SUCCESS, data })

const chooseProductImageBegin = () => ({type : PRODUCT_CHECKBOX_BEGINS})

const chooseProductImageSuccess = (data) => ({type : PRODUCT_CHECKBOX_SUCCESS, data})

const chooseProductImageFailure = () => ({type : PRODUCT_CHECKBOX_FAILURE})


const deleteProductBegins = () => ({ type: DELETE_PRODUCT_BEGINS })

const deleteProductSuccess = (data) => ({ type: DELETE_PRODUCT_SUCCESS, data })

const deleteProductFailure = () => ({ type: DELETE_PRODUCT_FAILURE })



// PRODUCT GET LIST
export const actionGetProduct = (limit, offset, search, sort, params) => async (dispatch) => {

    dispatch(getProductListBegins())
    try {
        const res = await api.get(`/admin/products`,
            { params: { limit, offset, search, sortBy: sort?.sortBy, sortOrder: sort?.sortOrder, ...params } })
        if (res.status === 200) {
            const response = res.data || { response: { data: [], total: 0 } }
            dispatch(getProductListSuccess(response))
            return { success: true, message: 'success', data: response?.data }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getProductListFailure())
        return { success: false, message: message }
    }

}

// GET SELECTED PRODUCT FILTER
export const actionGetSelectedProductFilters = (data) => async (dispatch) => {
    try {
        dispatch(getSelectedProductFilterSuccess(data))
    }
    catch (error) {
        return { success: false, message: error }
    }

}

// GET SELECTED PRODUCT FILTER
export const actionGetProductPagination = (data) => async (dispatch) => {
    try {
        dispatch(getProductPaginationSuccess(data))
    }
    catch (error) {
        return { success: false, message: error }
    }

}

// GET PRODUCT SEARCH
export const actionGetProductSearch = (data) => async (dispatch) => {
    try {
        dispatch(getProductSearchSuccess(data))
    }
    catch (error) {
        return { success: false, message: error }
    }

}

// PRODUCT GET DETAILS
export const actionGetProductDetails = (id) => async (dispatch) => {
    dispatch(getProductDetailsBegins())
    try {
        const res = await api.get(`/admin/products/${id}`)
        if (res.status === 200) {
            const response = res.data?.data || { response: { data: [], total: 0 } }
            dispatch(getProductDetailsSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getProductDetailsFailure())
        return { success: false, message: message }
    }
}

// PRODUCT FILTER API
export const actionFilterProduct = () => async (dispatch) => {
    dispatch(productFilterBegins())
    try {
        const res = await api.get(`customer/filters`)
        if (res.status === 200) {
            const response = res.data?.data || { response: { data: [], total: 0 } }
            dispatch(productFilterSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(productFilterFailure())
        return { success: false, message: message }
    }
}


export const actionUpdateSortOptions = (data) => async (dispatch) => {
    dispatch(updateSortOptions(data))
}


// CREATE PRODUCT PERMISSIONS
export const actionCreateProductPermissions = (payload) => async (dispatch) => {
    dispatch(createproductPermissionBegins())
    try {
        const res = await api.post(`/admin/product-settings`, payload)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Something went wrong' }
            dispatch(createproductPermissionSuccess())
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createproductPermissionFailure())
        return { success: false, message: message }
    }
}

// GET WISHLISTS    
export const actionGetWishlists = (page, limit) => async (dispatch) => {
    dispatch(getWishListsBegins())
    try {
        const res = await api.get(`/customer/wish-list`, { params: { limit, offset: page } })
        if (res.status === 200) {
            const response = res.data?.data || { response: { data: [], total: 0 } }
            dispatch(getWishListsSuccess(response));
            return { success: true, message: 'success', data: response }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getWishListsFailure())
        return { success: false, message: message }
    }
}


// GET WISHLIST ADDED USERS OF PRODUCT
export const actionGetWishListAddedUsers = (id) => async (dispatch) => {
    dispatch(getWishListAddedUsersBegins())
    try {
        const res = await api.get(`/admin/product-wishlist-users`, { params: { productId: id } })
        if (res.status === 200) {
            const response = res.data.data || { response: { data: [], total: 0 } }
            dispatch(getWishListAddedUsersSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getWishListAddedUsersFailure())
        return { success: false, message: message }
    }
}

// PRODUCT VISIBILITY STATUS UPDATE
export const actionProductVisibilityStatusUpdate = (id, data) => async (dispatch) => {
    dispatch(productVisibilityStatusBegins());
    try {
        const res = await api.put(`/admin/products/${id}/visibility`, data)
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(productVisibilityStatusSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(productVisibilityStatusFailure())
        return { success: false, message: message }
    }
}

// GET CUSTOMERS LISTS ADDED PRODUCT IN CART
export const actionGetProductAddedInCartUsersList = (id) => async (dispatch) => {
    dispatch(getProductAddedInCartUsersListBegins())
    try {
        const res = await api.get(`/admin/product-cart-users`, { params: { productId: id } })
        if (res.status === 200) {
            const response = res.data.data || { response: { data: [], total: 0 } }
            dispatch(getProductAddedInCartUsersListSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getProductAddedInCartUsersListFailure())
        return { success: false, message: message }
    }
}

// GET CUSTOMERS LIST ADDED PRODUCT AS INTERESTED
export const actionGetAddedInterestedUsersList = (id) => async (dispatch) => {
    dispatch(getAddedInterestedUsersListBegins())
    try {
        const res = await api.get(`/admin/product-interst-users`, { params: { productId: id } })
        if (res.status === 200) {
            const response = res.data.data || { response: { data: [], total: 0 } }
            dispatch(getAddedInterestedUsersListSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getAddedInterestedUsersListFailure())
        return { success: false, message: message }
    }
}

// GET PRODUCT ORDERS LIST
export const actionGetProductOrdresList = (limit, offset, params, id) => async (dispatch) => {
    dispatch(getProductOrdresListBegins())
    try {
        const res = await api.get(`/admin/product-orders`, { params: { limit, offset, params, productId: id } })
        if (res.status === 200) {
            const response = res.data || { response: { data: [], total: 0 } }
            dispatch(getProductOrdresListSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getProductOrdresListFailure())
        return { success: false, message: message }
    }
}

// GET RATINGS AND FEEDBACKS LIST
export const actionGetRatingsAndFeedbacksList = (id) => async (dispatch) => {
    dispatch(getRatingsAndFeedbacksListBegins())
    try {
        const res = await api.get(`/admin/product-order-ratings`, { params: { productId: id } })
        if (res.status === 200) {
            const response = res.data.data || { response: { data: [], total: 0 } }
            dispatch(getRatingsAndFeedbacksListSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getRatingsAndFeedbacksListFailure())
        return { success: false, message: message }
    }
}

//UPLOAD PRODUCT IMAGE
export const actionUploadProductImage = (id, data) => async (dispatch) => {
    dispatch(uploadProductImageBegins());
    try {
        const res = await api.post(`/admin/products/${id}/image-upload`, data)
        if (res.status === 200) {
            dispatch(uploadProductImageSuccess())
            return { success: true, message: 'Product image added successfully' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(uploadProductImageFailure())
        return { success: false, message: message }
    }
}

// DELETE PRODUCT IMAGE
export const actionDeleteProductImage = (data) => async (dispatch) => {
    dispatch(deleteProductImageBegins());
    try {
        const res = await api.delete('/admin/products/image/delete',  {params: data} );
        const { message } = res?.data;
        dispatch(deleteProductImageSuccess());
        if (res.status === 200) {
            return { success: true, message };
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(deleteProductImageFailure());
        return { success: false, message: message };
    }
}


export const actionChooseProductImage = ( id,data) => async (dispatch) => {
    dispatch(chooseProductImageBegin());
    try {
        const res = await api.post(`/admin/product-image-choose`, id,data);
        if (res.status === 200) {
            dispatch(chooseProductImageSuccess(data));
            return { success: true, message: 'Product image chosen successfully' };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(chooseProductImageFailure());
        return { success: false, message: message };
    }
};

export const deleteProductImage = (productId,id, product_id) => async (dispatch) => {
    dispatch(deleteProductBegins()); 
    try {
        const res = await api.delete(`/admin/product/${productId}/product-choose-image-delete`, { product_id :id} );
       
        if (res.status === 200) {
            dispatch(deleteProductSuccess(product_id));
            return { success: true, message: 'Product image deleted successfully' };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(deleteProductFailure()); 
        return { success: false, message: message };
    }
};



export const actionChooseCheckboxImage = (id,is_image_choose) => async (dispatch) => {
    dispatch(chooseProductImageBegin());
    try {
        const res = await api.post(`/admin/product-image-choose`,{product_item_id:id, is_image_choose});
        if (res.status === 200) {
            dispatch(chooseProductImageSuccess());
            return { success: true, message: 'Product image chosen successfully' };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' };
        dispatch(chooseProductImageFailure());
        return { success: false, message: message };
    }
};