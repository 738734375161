import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import { Avatar } from "..";
import { useEffect } from "react";

export const CustomCarousal = ({ options, variant, loader, handleClick, className, customClass, handleSliderImageData, carouselRef }) => {
  const [imageLists, setImageLists] = useState([])
  const newOptions = options.filter(img => img?.image !== null)

  const productSettings = {
    customPaging: (idx) => (
      <span className="image-options">
        {newOptions?.length > 0 && newOptions[idx]?.image && newOptions?.length > 0 ?
          <span onClick={() => handleSliderImageData(newOptions[idx])}>
            <Avatar src={newOptions[idx]?.image} variant={"product-details-small-items"} />
          </span> : <Avatar src={"/images/noimage.svg"} errorImage="/images/noimage.svg" variant={"product-details-small-items"} />
        }
      </span>
    ),
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    slide: '> div',
  };



  const promotionSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slide: '> div',
  };

  const quickPromotionSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slide: '> div',
    cssEase: 'linear',
    initialSlide: 1
  }


  const renderCarousal = () => {
    switch (variant) {
      case 'product-details':

        return (<Slider {...productSettings} ref={carouselRef} >
          {newOptions?.length > 0 && newOptions.length > 0 ?
            newOptions?.map((option) =>
              <div key={option.id} className="active-images">
                <Avatar src={option?.image} variant={"product-details"} />
              </div>) :
            <div className="active-images">
              <Avatar src={"/images/noimage.svg"} variant={"product-details"} />
            </div>}
        </Slider>)

      case 'promotion-details':
        return (
          <>
            {imageLists?.length > 0 && <Slider {...promotionSettings} className="promo-details-card p-2">
              {imageLists?.length > 0 ?
                imageLists?.map((option) =>
                  <div key={option?.id} className="active-images">
                    <Avatar src={option?.image} errorImage="/images/noimage.svg" variant={"product-details"} />
                  </div>) :
                <div className="active-images">
                  <Avatar src="/images/noimage.svg" variant={"product-details"} />
                </div>
              }
            </Slider>}
          </>)

      case 'quick-promotions':

        return (
          <>
            {imageLists?.length > 0 && <Slider {...quickPromotionSettings} className="quick-promotions p-0">
              {imageLists?.map((option) =>
                <div key={option?.id} className="active-images ">
                  <Avatar src={option?.image} errorImage="/images/noimage.svg" variant={"quick-promotion"} />
                </div>)
              }
            </Slider>}
          </>)
      case 'quick-promotions-list-items':
        return (
          <div onClick={handleClick}>
            {imageLists?.length > 0 ? <Slider {...quickPromotionSettings} className={`quick-promotions-list-items p-0 ${customClass}`}>
              {imageLists?.map((option) =>
                <div key={option?.id} className="active-images ">
                  <Avatar src={option?.image} errorImage="/images/noimage.svg" variant={"quick-promotion"} />
                </div>)
              }
            </Slider> :
              <Slider {...quickPromotionSettings} className={`quick-promotions-list-items p-0 ${customClass}`}>
                <div className="active-images ">
                  <Avatar src="/images/noimage.svg" errorImage="/images/noimage.svg" variant={"quick-promotion"} />
                </div>
              </Slider>}
          </div>)

      default:
        break;
    }
  }

  useEffect(() => {
    if (options?.length > 0) {
      setImageLists(options)
    }
  }, [options])


  return (
    <div className={`carousel ${className ? className : ''}`} >
      {renderCarousal()}
    </div >
  );
};
